import React from "react";
import { Link } from "gatsby";
import * as styles from "./AboutTeam.module.scss";
import UIUX from "../../images/uiux-designer.svg";
import FrontEnd from "../../images/front-end-developer.svg";
import BackEnd from "../../images/back-end-developer.svg";
import MobileApp from "../../images/mobile-app-developer.svg";
import ProjectManager from "../../images/project-manager.svg";
import { Col, Row } from "../layout/Grid";
import ScrollReveal from "../layout/ScrollReveal";
import { mainURL } from "../../config/url";

const team = [
  { img: UIUX, name: "UI/UX Designer" },
  { img: FrontEnd, name: "Front-end Developer" },
  { img: BackEnd, name: "Back-end Developer" },
  { img: MobileApp, name: "Mobile App Developer" },
  { img: ProjectManager, name: "Project Manager" },
];

const AboutTeam = () => {
  return (
    <section>
      <div className="title">Team</div>
      <div className="section wrapper">
        <Row>
          <Col>
            <ScrollReveal effect="slide-right" delay={50}>
              <h2 className={`h2 ${styles["title"]}`}>
                Simple people bringing together complex ideas
              </h2>
              <Link to={mainURL.career} className="btn">
                Join the team
              </Link>
            </ScrollReveal>
          </Col>
          <Col width="50%" className={styles.teamDesc}>
            <ScrollReveal effect="slide-left" delay={50}>
              <p>
                We bring together an experienced and energetic group of people
                with a focused mission—to transform traditional businesses to
                win in the digital revolution. Our team's strong ambitions,
                ceiling standards and agility extend from the beginning of your
                project to the finish line.
              </p>
              <p>
                Comprised of a group of strategists, designers and developers,
                we don't focus exclusively on either design or functionality,
                our focus is on both; we believe they are both important. Each
                project is carefully curated to ensure it meets both design and
                functionality.
              </p>
            </ScrollReveal>
          </Col>
        </Row>
        <ul className={styles["list"]}>
          {team.map((item, index) => {
            return (
              <li key={index}>
                <ScrollReveal effect="slide-up" delay={index * 100 + 50}>
                  <img src={item.img} alt={item.name} />
                  <div className="h6">{item.name}</div>
                </ScrollReveal>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default AboutTeam;

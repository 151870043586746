import React from "react";
import * as styles from "./about.module.scss";
import PageLayout from "../components/layout/PageLayout";
import Masthead from "../components/layout/Masthead";
import Seo from "../components/layout/SEO";
import Intro from "../components/layout/Intro";
import AboutTeam from "../components/about/AboutTeam";
import AboutClient from "../components/about/AboutClient";
import ScrollReveal from "../components/layout/ScrollReveal";

import aboutAnim from "../assets/json/about.json";

const About = () => {
  return (
    <PageLayout includeCTA anim={aboutAnim}>
      <Seo title="About - Simplicitic Innovations Sdn Bhd" />
      <Masthead title="Digital Agency + Software Company" tag="About Us" />
      <Intro>
        <ScrollReveal effect="slide-up" delay={50}>
          <p className={`h4 text-center ${styles["intro"]}`}>
            Simplicity is the best when it comes to solutions. As a digital
            agency that makes technology work for your business, we must keep it
            simple. So anyone can easily understand how technology works. We
            strive for simple solutions by challenging the boundaries of design
            perfection and technical constraints. Simply put, we are here to
            offer you a one-stop solution to all your online marketing needs.
          </p>
        </ScrollReveal>
      </Intro>
      <AboutTeam />
      <AboutClient />
    </PageLayout>
  );
};

export default About;

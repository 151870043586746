import React from "react";
import * as styles from "./AboutClient.module.scss";
import { Col, Row } from "../layout/Grid";
import { StaticImage } from "gatsby-plugin-image";
import ScrollReveal from "../layout/ScrollReveal";

const clientList = [
  <StaticImage src="../../images/kakiseni-logo.png" alt="Kakiseni" />,
  <StaticImage src="../../images/moodboard-logo.png" alt="Moodboard" />,
  <StaticImage src="../../images/avery-logo.png" alt="Avery-Weigh Tronix" />,
  <StaticImage src="../../images/paragrene-logo.png" alt="Paragrene" />,
  <StaticImage src="../../images/rspo-logo.png" alt="RSPO" />,
  <StaticImage
    src="../../images/orbiting-logo.png"
    alt="Orbiting Scientific"
  />,
  <StaticImage src="../../images/techbond-logo.png" alt="Techbond" />,
  <StaticImage src="../../images/econframe-logo.png" alt="Econframe" />,
  <StaticImage src="../../images/eyemeditech-logo.png" alt="Eyemeditech" />,
  <StaticImage
    src="../../images/natural-orient-logo.png"
    alt="Natural Orient"
  />,
  <StaticImage src="../../images/county-garden-logo.png" alt="County Garden" />,
  <StaticImage src="../../images/psp-logo.png" alt="PSP" />,
  <StaticImage src="../../images/boh-logo.png" alt="BOH Cameronian Awards" />,
  <StaticImage src="../../images/vsi-logo.png" alt="Kakiseni" />,
  <StaticImage src="../../images/esona-logo.png" alt="Esona Online" />,
  <StaticImage src="../../images/vk-logo.png" alt="Victor Kong" />,
];

const AboutClient = () => {
  return (
    <section className="section-light">
      <div className="title">Client</div>
      <div className="section wrapper">
        <Row>
          <Col width="40%">
            <ScrollReveal effect="slide-right" delay={50}>
              <h2 className="h2">The friends we’ve made along the way</h2>
            </ScrollReveal>
          </Col>
          <Col width="50%">
            <ScrollReveal effect="slide-left" delay={50}>
              <p>
                Without our clients, SIMPLICITIC would not exist. The work we do
                cuts across all industries, and we have collaborated with both
                small and large organisations.
              </p>
              <div className={styles["industry"]}>
                <ul className="custom-list">
                  <li>Professional Services</li>
                  <li>Consumer Products</li>
                  <li>Food & Beverage</li>
                  <li>Advertising Agencies</li>
                </ul>
                <ul className="custom-list">
                  <li>Real Estate</li>
                  <li>Education</li>
                  <li>Healthcare</li>
                </ul>
                <ul className="custom-list">
                  <li>NGO</li>
                  <li>Retail</li>
                  <li>More…</li>
                </ul>
              </div>
            </ScrollReveal>
          </Col>
        </Row>
        <ul className={styles["list"]}>
          {clientList.map((item, index) => {
            return (
              <li key={index}>
                <ScrollReveal effect="zoom-in" delay={index * 50 + 50}>
                  {item}
                </ScrollReveal>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default AboutClient;
